<template>
  <div v-if="employeeMenuEnabled" class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('Employees.Title') }}</h3>
      </div>
    </div>
    <div class="card-body">
      <div v-if="emptyCompaniesList">
        <p>{{ $t('Employees.No_Company_Yet') }}</p>
      </div>
      <div v-else class="row">
        <div class="col-md-3">
          <label>{{ $t('Employees.Company_Select_Title') }}</label>
          <select class="form-control" v-model="filter.company_id">
            <option v-for="company in companiesList" :value="company.id">{{ company.name }}</option>
          </select>
        </div>
        <div class="col-md-4"></div>
        <div :class="showActions ? 'col-md-3' : 'col-md-5'" class="mt-8">
          <input class="form-control"
                 :disabled="errorMessage"
                 v-model="filter.searchEmployee"
                 :placeholder="$t('Employees.Enter_The_Name')">
        </div>
        <div v-if="showActions" class="col-md-2 mt-8">
          <button @click="addEditEmployeeModal('add')" :disabled="errorMessage" class="btn btn-light btn-hover-primary float-right">
            <font-awesome-icon class="mr-1" icon="plus"></font-awesome-icon>
            {{ $t('Employees.New_Employee') }}
          </button>
        </div>
      </div>
      <div v-if="cardInfo" class="cardInfo">
        <p>{{ $t('cardInfo.workPlacesOrdered') }}: {{ cardInfo.workPlacesOrdered }}</p>
        <p>{{ $t('cardInfo.cardLimitNoChargeable') }}: {{ cardInfo.cardLimitNoChargeable }}</p>
        <p>{{ $t('cardInfo.cardLimitLinked') }}: {{ cardInfo.cardLimitLinked }}</p>
        <p>{{ $t('cardInfo.cardUses') }}: {{ cardInfo.cardUses }}</p>
        <p>{{ $t('cardInfo.cardAdditional') }}: {{ cardInfo.cardAdditional }}</p>
      </div>

      <!--begin: Datatable-->
      <core-datatable v-if="!errorMessage && companiesList.length > 0"
                      :loaded="loaded" v-bind:columns.sync=columns :showFooter="true"
                      :items="employeesList" v-bind:filter.sync="filter"
                      :totalCount="userTotalCount" class="mt-5">
        <template #photo="{ item }">
          <div class="symbol symbol-100 mr-5">
            <div v-if="item.avatar" class="symbol-label"
                 :style="'background-image:url(' + item.avatar.url + ')'"></div>
          </div>
        </template>
        <template #name="{ item }">
          <p>{{ item.last_name }}</p>
          {{ item.first_name }} {{ item.father_name }}
        </template>
        <template #email="{ item }">
          {{ item.email }}
        </template>
        <template #phone="{ item }">
          {{ item.phone }}
        </template>
        <template #card="{ item }">
          <img v-if="item.card" src="@/modules/user/assets/ic_payment.png" alt="card" class="card" />
        </template>
        <template v-if="showActions" #actions="{ item }">
          <a class="btn btn-sm btn-icon btn-light-success mr-2"
             @click="addEditEmployeeModal('edit', item)">
            <font-awesome-icon class="icon-md" icon="edit"></font-awesome-icon>
          </a>
          <a class="btn btn-sm btn-icon btn-light-danger mr-2"
             @click="deleteEmployeeModal(item)">
            <font-awesome-icon class="icon-md" icon="ban"></font-awesome-icon>
          </a>
        </template>
      </core-datatable>
      <div :loaded="loaded" class="mt-10">
        <p class="text-danger">{{ errorMessage }}</p>
      </div>
      <!--end: Datatable-->
      <!--begin::Employee Modal-->
      <EmployeeModal
          :showEmployeeModal="showEmployeeModal"
          :companyId="filter.company_id"
          :employeeType="employeeType"
          :employeeData="employeeData"
          @updateEmployeeData="updateEmployeeData"
          @updateShowEmployeeModal="updateShowEmployeeModal"/>
      <!--end::Employee Modal-->
      <!--begin::Delete Employee Modal-->
      <b-modal v-model="showDeleteEmployeeModal" size="md" @ok="goDelete"
               :title="$t('Employees.Modal_Delete_Title')"
               :ok-title="$t('Employees.Modal_Yes_Delete')"
               :cancel-title="$t('Employees.Modal_Cancel')">
        <div class="d-flex flex-column align-items-center">
          <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
            <div class="symbol-label">
              <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
            </div>
          </div>
          <div class="font-weight-bolder">{{ $t('Employees.Modal_Delete_Desc') }}</div>
        </div>
      </b-modal>
      <!--end::Delete Employee Modal-->
      <b-modal v-model="showModalMessages" size="md"
               @ok="showModalMessages = false"
               @hidden="showModalMessages = false"
               centered
               hide-header
               hide-footer
               no-close-on-backdrop>
        <b-form class="text-center">
          <p class="m-10">{{ successMessage }}</p>
          <b-button size="sm" variant="primary" @click="showModalMessages = false; successMessage = null"
                    style="margin: 20px auto 0; display: block;">
            {{ $t('meeting_room.Ok') }}</b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import api from "@/plugins/api";

import EmployeeModal from "./EmployeeModal";

export default {
  name: "Employees",
  components: {
    EmployeeModal
  },
  data() {
    return {
      emptyCompaniesList: false,
      showEmployeeModal: false,
      showDeleteEmployeeModal: false,
      showActions: false,
      filter: {
        sort: [],
        company_id: null,
        searchEmployee: null,
        page: 1,
        'per-page': 20,
      },
      columns: [
        {text: 'Employees.columns.photo', slot: 'photo', key: 'photo', sort: false},
        {text: 'Employees.columns.name', slot: 'name', key: 'name', sort: false},
        {text: 'Employees.columns.email', slot: 'email', key: 'email', sort: false},
        {text: 'Employees.columns.phone', slot: 'phone', key: 'phone', sort: false},
        {text: 'cardInfo.AccessCard', slot: 'card', key: 'card', sort: false},
        {text: 'Employees.columns.actions', slot: 'actions', sort: false}
      ],
      employeeType: 'add',
      employeeData: {
        first_name: '',
        last_name: '',
        father_name: '',
        email: '',
        phone: ''
      },
      selected: {},
      showModalMessages: false,
      successMessage: null,
      cardInfo: {
        workPlacesOrdered: null,
        cardLimitNoChargeable: null,
        cardLimitLinked: null,
        cardUses: null,
        cardAdditional: null
      }
    }
  },
  mounted() {
    this.$store.dispatch('user/GetCompanies').then(res => {
      if (res.length === 0) this.emptyCompaniesList = true;
      else this.filter.company_id = res[0].id
    });
  },
  computed: {
    ...mapGetters({
      identity: 'user/identity',
      companiesList: 'user/companiesList',
      employeesList: 'user/employeesList',
      errorMessage: 'user/errorMessage',
      userPage: 'user/page',
      userPerPage: 'user/perPage',
      userTotalCount: 'user/totalCount',
      loaded: 'user/loaded'
    }),
    employeeMenuEnabled() {
      return this.identity.company_ids && !this.identity.company_ids.includes(3886) && !this.identity.company_ids.includes(3510)
    }
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
  methods: {
    fetch() {
      this.showActions = false;
      for (let i in this.columns) {
        switch (this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-' + this.columns[i].key
            break;
        }
      }
      this.$store.dispatch('user/GetCompanyEmployees',
          {
            id: this.filter.company_id,
            q: this.filter.searchEmployee,
            page: this.filter.page,
            perPage: this.filter['per-page'],
            all: null
          }).then(() => {
              this.$store.dispatch('user/GetCompanyEmployees',
                  {
                    id: this.filter.company_id,
                    q: this.filter.searchEmployee,
                    page: this.filter.page,
                    perPage: this.filter['per-page'],
                    all: 'allEmployees'
                  }).then(res => {
                    document.getElementById('app').scrollIntoView();
                    let admin = res.filter(item => item.id === this.identity.id && item.role === 'administrator')
                    if (admin.length > 0) this.showActions = true;
                    const userIDs = this.$store.getters['user/employeesList'].map(item => item.id)
                    this.$store.dispatch('user/hasCardKey', userIDs).then(response => {
                      for(const [userID, value] of Object.entries(response)) {
                        if(!value) continue
                        item.email = item.email + ' '
                        item.card = true
                      }
                    })
              });
      });
      this.$store.dispatch('user/cardInfo', this.filter.company_id).then(cardInfo => {
        this.cardInfo = cardInfo
      })

    },
    addEditEmployeeModal(type, item) {
      this.showEmployeeModal = true;
      if (type === 'edit') {
        this.employeeType = 'edit';
        this.employeeData = item;
      }
    },
    deleteEmployeeModal(item) {
      this.selected = item;
      this.showDeleteEmployeeModal = true;
    },
    goDelete() {
      this.$store.commit('user/UpdateLoaded', false);
      api.delete('/v2/companies/' + this.filter.company_id + '/staff?user_id=' + this.selected.id)
          .then(() => {
            this.fetch();
            this.showModalMessages = true;
            this.successMessage = this.$t("Employees.Modal_Delete_Success_Message");
          });
    },
    updateEmployeeData(value) {
      this.employeeData = value.employee;
      this.employeeType = value.type;
      if (value.changed) {
        this.showModalMessages = true;
        if (value.type === 'add') this.successMessage = this.$t("Employees.Modal_Add_Success_Message");
        else this.successMessage = this.$t("Employees.Modal_Edit_Success_Message");
      }
    },
    updateShowEmployeeModal(value) {
      this.showEmployeeModal = value;
      this.fetch();
    }
  }
}
</script>

<style scoped>
.cardInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 2em;
}
.cardInfo p {
  width: 40%;
  flex-wrap: wrap;
  margin: 0;
}
img.card {
  height: 16px;
}
</style>
